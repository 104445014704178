import React, {Component} from "react";


class Main extends Component {
    
   
    render() {
        return (       
            
        <div className="container">
            <section className="mt-5">
            <div className="columns">
                <div >
                <section className="mt-5">
                    <div className="card">
                    <div className="card-content">
                        <div className="content">
                        <h2>Players </h2>
                        <ul className="ml-0">
                            Player
                        </ul>
                        </div>
                    </div>
                    </div>
                </section>
                
                </div>
            </div>
            </section>
        </div>

        )
    }
}



export default Main;
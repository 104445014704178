// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.getPlayers()
        .call();
      let cost = await store
        .getState()
        .blockchain.smartContract.methods.entryCost()
        .call();
      let pot = await store
        .getState()
        .blockchain.smartContract.methods.getBalance()
        .call();
      let minPlayers = await store
        .getState()
        .blockchain.smartContract.methods.playerCount()
        .call();
      let round = await store
        .getState()
        .blockchain.smartContract.methods.lotteryId()
        .call();
      let fCost = cost * .000000000000000001;
      let playerLeft = minPlayers - totalSupply.length;
      let futureEntry =( playerLeft * fCost);
      let potInt = parseInt(pot) * .000000000000000001;
      let totalPot = (potInt + futureEntry);
      

      dispatch(
        fetchDataSuccess({
          totalSupply,
          round,
          cost,
          pot,
          fCost,
          minPlayers,
          futureEntry,
          totalPot,
          playerLeft,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }

  };
};

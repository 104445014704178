const initialState = {
  loading: false,
  minPlayers: 0,
  round: 0,
  playerLeft: 0,
  pot: 0,
  cost: 0,
  fCost: 0,
  totalPot: 0,
  futureEntry: 0,
  players: [],
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      
      return {
        ...state,
        loading: false,
        players: action.payload.totalSupply,
        round: action.payload.round,
        playersEntered: action.payload.totalSupply.length,
        cost: action.payload.cost,
        minPlayers: action.payload.minPlayers,
        playerLeft: action.payload.playerLeft,
        pot: action.payload.pot,
        fCost: action.payload.fCost,
        futureEntry: action.payload.futureEntry,
        totalPot: action.payload.totalPot,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};


export default dataReducer;
